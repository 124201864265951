import type { ReactNode } from 'react';
import AssuredWorkloadTwoToneIcon from '@mui/icons-material/AssuredWorkloadTwoTone';
import AccessTimeTwoToneIcon from '@mui/icons-material/AccessTimeTwoTone';
import HomeTwoToneIcon from '@mui/icons-material/HomeTwoTone';
import SettingsIcon from '@mui/icons-material/Settings';
import { Groups, PersonOutlineTwoTone } from '@mui/icons-material';
import { useAuth } from 'contexts/AuthContext';

export interface MenuItem {
  link?: string;
  icon?: ReactNode;
  badge?: string;
  items?: MenuItem[];
  name: string;
  display?: boolean;
}

export interface MenuItems {
  items: MenuItem[];
  heading: string;
}

export const useMenuItems = (): MenuItems[] => {
  const auth = useAuth();
  return [
    {
      heading: 'Sections',
      items: [
        { name: 'Home', icon: HomeTwoToneIcon, link: '/home' },
        {
          name: 'Timesheets',
          icon: AccessTimeTwoToneIcon,
          items: [
            {
              name: 'All',
              link: '/timesheet/all'
            },
            {
              name: 'Approved',
              link: '/timesheet/AwaitingPayment'
            },
            {
              name: 'Pay Reports',
              link: '/PayReport'
            }
          ],
          display: auth.user.canManageTimesheets ? true : false
        },
        {
          name: 'Jobs',
          icon: AssuredWorkloadTwoToneIcon,
          link: '/job',
          display: auth.user.canManageJobs ? true : false
        },
        {
          name: 'Users',
          icon: PersonOutlineTwoTone,
          link: '/user',
          display: auth.user.canManageUsers ? true : false
        },
        {
          name: 'Teams',
          icon: Groups,
          link: '/team',
          display: auth.user.canManageTeams ? true : false
        },
        {
          name: 'Admin',
          icon: SettingsIcon,
          link: '/admin',
          display: auth.user.isTenantAdminUser ? true : false
        }
      ]
    }
  ];
};
