import { ReactNode } from 'react';
import { useQueryClient } from 'react-query';
import { usePubSubSubscription } from 'hooks/usePubSub';

const QueryCacheManager = ({ children }: { children: ReactNode }) => {
  const queryClient = useQueryClient();

  // Subscribe to various events and invalidate cache if necessary
  usePubSubSubscription(['job'], () => {
    queryClient.invalidateQueries('job');
  });

  return <>{children}</>;
};

export default QueryCacheManager;
