import { get, post, put } from './apiBase';
import {
  AcceptInviteRequest,
  EditUserRequestParams,
  ICurrentUser,
  UserDto
} from 'types/User';
import { auth0Config } from 'config';
import {
  LoginResponse,
  RequestEmailVerificationCodeResponse,
  VerifyEmailRequest,
  VerifyEmailResponse,
  UserInviteResponse,
  ResetPasswordResponse
} from 'types/Auth';

const login = (emailAddress: string, password: string) => {
  const { clientId } = auth0Config;
  return post<LoginResponse>('User/Login', {
    clientId,
    emailAddress,
    password
  });
};

const getCurrentUser = () => {
  return get<ICurrentUser>('User/Current');
};

const verifyEmailAddress = (code: string) => {
  const verifyEmailRequest: VerifyEmailRequest = { code };
  return post<VerifyEmailResponse>(
    'User/VerifyEmailAddress',
    verifyEmailRequest
  );
};

const triggerPasswordReset = (emailAddress: string) => {
  const { clientId } = auth0Config;
  return post<ResetPasswordResponse>('User/ResetPassword', {
    clientId,
    emailAddress
  });
};

const requestEmailVerificationCode = () => {
  return post<RequestEmailVerificationCodeResponse>(
    'User/RequestEmailVerificationCode'
  );
};

const sendInvites = (emails: string[]) => {
  return post<UserInviteResponse>('User/Invite', { emails });
};

const getUsers = () => {
  return get<UserDto[]>('User');
};

const updateUser = (params: EditUserRequestParams) => {
  const { userId, ...requestData } = params;
  return put<UserDto>(`User/${userId}`, requestData);
};

const acceptInvite = (data: AcceptInviteRequest) => {
  return post(`User/register`, data);
};

const userApi = {
  login,
  getCurrentUser,
  triggerPasswordReset,
  requestEmailVerificationCode,
  verifyEmailAddress,
  sendInvites,
  getUsers,
  updateUser,
  acceptInvite
};

export default userApi;
