import numeral from 'numeral';
import format from 'date-fns/format';
import { round as lodashRound } from 'lodash';

export const QueryKeys = {
  GetTimesheetsAwaitingPayment: 'GetTimesheetsAwaitingPayment',
  GetTimesheet: 'GetTimesheet',
  GetTimesheetHistory: 'GetTimesheetHistory',
  GetWorkItems: 'GetWorkItems',
  GetPayReport: 'GetPayReport',
  GetPayReports: 'GetPayReports',
  GetPayReportDownload: 'GetPayReportDownload',
  Users: 'Users',
  Teams: 'Teams',
  GetTenantData: 'GetTenantData',
  XeroIntegrationStatus: 'XeroIntegrationStatus',
  StripeData: 'StripeData'
};

const parseError = (error: any) => {
  if (!Boolean(error)) {
    return '';
  }

  if (typeof error === 'string') {
    return error;
  }

  if (error?.response?.data?.errors) {
    return Object.values(error.response.data.errors).join(' ');
  }

  return (
    error?.response?.data?.message ||
    error?.response?.data?.Message ||
    error?.response?.data?.detail ||
    error?.response?.data?.title ||
    error?.response?.data ||
    error?.message ||
    `❌ There is an error: ${JSON.stringify(error)}`
  );
};

export const getErrorMessage = (error: any) => {
  const err = parseError(error);

  if (typeof err === 'string') {
    return err;
  }

  return err;
};

export const getGbp = (value?: number) => {
  return `£${formatNumber(value)}`;
};

export const formatNumber = (
  number?: number,
  returnFormat: string = '#,#[.]##'
) => {
  if (!Boolean(number)) {
    return '0';
  }
  return numeral(number).format(returnFormat);
};

export const formatCurrency = (value?: number) => {
  return `£${formatNumber(value)}`;
};

export const isNumeric = (val: string | undefined): boolean => {
  if (!val) {
    return false;
  }

  return !isNaN(Number(val));
};

export const round = (n: number, precision?: number) => {
  return lodashRound(n, precision);
};

export const dateFormats = {
  dto: 'yyyy-MM-dd',
  nice: 'E MMM do yyyy',
  niceShort: 'MMM do',
  niceShortDay: 'E MMM do',
  niceShortDayYear: 'E MMM do yyyy',
  niceShortDayYearWithTime: 'E MMM do yyyy HH:mm',
  niceShortDayWithTime: 'E MMM do HH:mm'
};

export const formatDt = (date: Date, returnFormat: string) => {
  return format(date, returnFormat);
};

const _currentYear = new Date().getFullYear();

export const formatDate = (
  date: Date | string,
  returnFormat: string = dateFormats.dto,
  hideYearIfCurrent: boolean = false
) => {
  if (!date) {
    return '';
  }
  const dateToUse = typeof date === 'string' ? new Date(date) : date;
  if (hideYearIfCurrent) {
    if (returnFormat.includes('yyyy')) {
      if (dateToUse.getFullYear() === _currentYear) {
        returnFormat = returnFormat.replaceAll('yyyy', '');
      }
    }
  }

  return formatDt(dateToUse, returnFormat);
};

export const getNiceTime = (uglyTime: string) => {
  const split = uglyTime.split(':');
  split.pop();
  return split.join(':');
};
