import { ReactNode } from 'react';
import {
  Alert,
  Box,
  Button,
  Card,
  Divider,
  Grid,
  Typography
} from '@mui/material';
import { Container, Stack } from '@mui/system';
import Logo from 'components/LogoSign';
import { LogoWrapper } from './Shared';

export const AnonymousLayout = ({
  children,
  title,
  subTitle,
  subTitle2
}: {
  children: ReactNode;
  title: string;
  subTitle?: string;
  subTitle2?: string;
}) => {
  return (
    <Grid container>
      <Grid item md={12}>
        <Box
          sx={{
            flex: 1,
            display: 'flex',
            alignItems: 'center',
            justifyContent: 'center'
          }}
        >
          <Box>
            <Container
              maxWidth="sm"
              sx={{
                display: 'flex',
                flexDirection: 'column',
                justifyContent: 'space-evenly',
                p: 3
              }}
            >
              <LogoWrapper>
                <Logo />
              </LogoWrapper>

              <Card sx={{ mt: 3, px: 2, pt: 2, pb: 2 }}>
                <Box>
                  <Typography variant="h2" sx={{ mb: 1, textAlign: 'center' }}>
                    {title}
                  </Typography>
                </Box>

                {subTitle ? (
                  <Typography
                    variant="h4"
                    color="text.secondary"
                    fontWeight="normal"
                    sx={{ mb: 3, textAlign: 'center' }}
                  >
                    {subTitle}
                  </Typography>
                ) : null}

                {subTitle2 ? (
                  <Alert severity="success">{subTitle2}</Alert>
                ) : null}

                {children}
              </Card>
            </Container>
            <Divider sx={{ mb: 2, mx: { xs: 0, sm: 3 } }} />
            <Box sx={{ mb: 3 }}>
              <Stack
                direction="row"
                justifyContent="center"
                alignItems="center"
              >
                <Button
                  variant="contained"
                  color="primary"
                  href="https://www.fluxtime.app/"
                >
                  &laquo; Back to website
                </Button>
              </Stack>
            </Box>
          </Box>
        </Box>
      </Grid>
    </Grid>
  );
};
