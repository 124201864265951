import { forwardRef } from 'react';
import { LinkProps, NavLink } from 'react-router-dom';

const getForwardRefNavLink = () => {
  return forwardRef<HTMLAnchorElement, LinkProps>((props, ref) => {
    return (
      <NavLink
        ref={ref}
        {...props}
        className={({ isActive }) => {
          return [props.className, isActive ? 'Mui-active' : '']
            .filter(Boolean)
            .join(' ');
        }}
      />
    );
  });
};

export default getForwardRefNavLink;
