import * as Yup from 'yup';
import { Formik } from 'formik';
import {
  Alert,
  Box,
  Card,
  Link,
  Typography,
  Container,
  Button,
  TextField,
  CircularProgress
} from '@mui/material';
import { Helmet } from 'react-helmet-async';
import { useAuth } from 'contexts/AuthContext';
import Logo from 'components/LogoSign';
import { getErrorMessage } from 'utils';
import userApi from 'apis/userApi';
import useSnackbarCustom from 'hooks/useSnackbarCustom';

const VerifyEmail = () => {
  const { user, verifyEmail } = useAuth();

  const { enqueueSnackbarSuccess, enqueueSnackbarError } = useSnackbarCustom();

  const handleGetNewCodeClick = async () => {
    try {
      await userApi.requestEmailVerificationCode();
      enqueueSnackbarSuccess('New code requested. Please check your email');
    } catch (error) {
      enqueueSnackbarError(
        `Error requesting new code: ${getErrorMessage(error)}`
      );
    }
  };

  return (
    <>
      <Helmet>
        <title>Verify Your Email Address</title>
      </Helmet>
      <Box
        sx={{
          height: '100%',
          display: 'flex',
          flex: 1,
          flexDirection: 'column'
        }}
      >
        <Box
          sx={{
            display: 'flex',
            width: '100%',
            flex: 1,
            p: '20px'
          }}
        >
          <Container maxWidth="sm">
            <Box sx={{ display: 'flex' }}>
              <Logo />
            </Box>
            <Card sx={{ mt: 3, px: 4, pt: 5, pb: 3 }}>
              <Box sx={{ mb: 2 }}>
                <Typography variant="h2" sx={{ mb: 1 }} align="center">
                  Verify Your Email Address
                </Typography>
                <Typography variant="body1">
                  Your email address has not yet been verified. An email has
                  been sent to <strong>{user.emailAddress}</strong> with a
                  verification code. Enter your code below or{' '}
                  <Link
                    variant="body1"
                    component="button"
                    onClick={handleGetNewCodeClick}
                  >
                    request a new code
                  </Link>
                  .
                </Typography>
              </Box>
              <Formik
                initialValues={{ code: '', submit: null }}
                validationSchema={Yup.object().shape({
                  code: Yup.string()
                    .length(6, 'Code must be 6 characters')
                    .required('Please enter your code')
                })}
                onSubmit={async (values, { setErrors }) => {
                  try {
                    await verifyEmail(values.code);
                    enqueueSnackbarSuccess('Email verification successful 🎉');
                  } catch (error) {
                    setErrors({ submit: getErrorMessage(error) });
                  }
                }}
              >
                {({
                  errors,
                  handleChange,
                  handleSubmit,
                  isSubmitting,
                  touched,
                  values
                }): JSX.Element => {
                  return (
                    <form noValidate onSubmit={handleSubmit}>
                      <TextField
                        error={Boolean(touched.code && errors.code)}
                        fullWidth
                        margin="normal"
                        helperText={touched.code && errors.code}
                        label="Verification Code"
                        name="code"
                        onChange={handleChange}
                        type="text"
                        value={values.code}
                        variant="outlined"
                        inputProps={{ maxLength: 6 }}
                        InputProps={{
                          endAdornment: (
                            <Button
                              startIcon={
                                isSubmitting ? (
                                  <CircularProgress size="1rem" />
                                ) : null
                              }
                              disabled={isSubmitting}
                              type="submit"
                            >
                              {isSubmitting ? 'Verifying' : 'Verify'}
                            </Button>
                          )
                        }}
                      />
                      {errors.submit && (
                        <Box mt={4}>
                          <Alert severity="error">{errors.submit}</Alert>
                        </Box>
                      )}
                    </form>
                  );
                }}
              </Formik>
            </Card>
          </Container>
        </Box>
      </Box>
    </>
  );
};

export default VerifyEmail;
