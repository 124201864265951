import { useEffect } from 'react';
import PubSub from 'pubsub-js';
import EventName from 'utils/events';

const subscribe = (
  event: PubSubJS.Message,
  fn: PubSubJS.SubscriptionListener<unknown>
) => {
  const token = PubSub.subscribe(event, fn);
  return token;
};

const unsubscribe = (subscriptionToken: string) => {
  PubSub.unsubscribe(subscriptionToken);
};

const configureSubscriptions = (
  eventsToSubscribeTo: string[],
  fn: () => void
) => {
  const subscriptionTokens = eventsToSubscribeTo.map((e) => subscribe(e, fn));
  const cleanup = () => {
    subscriptionTokens.forEach(unsubscribe);
  };
  return {
    subscriptionTokens,
    cleanup
  };
};

export const usePubSubSubscription = (events: EventName[], fn: () => void) => {
  useEffect(() => {
    const { cleanup } = configureSubscriptions(events, fn);
    return cleanup;
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  return { events };
};

export const usePubSubPublisher = () => {
  const publishEvent = (event: EventName, data?: unknown) => {
    return PubSub.publish(event, data);
  };

  return { publishEvent };
};

const usePubSub = {
  usePubSubSubscription,
  usePubSubPublisher
};

export default usePubSub;
