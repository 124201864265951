import { Box, Tooltip } from '@mui/material';
import { Link } from 'react-router-dom';
import { styled } from '@mui/material/styles';
import logo from 'assets/logo.png';

const LogoWrapper = styled(Link)(
  ({ theme }) => `
        color: ${theme.palette.text.primary};
        display: flex;
        text-decoration: none;
        margin: 0 auto;
        font-weight: ${theme.typography.fontWeightBold};
`
);

function Logo({ height = '60px' }: { height?: string }) {
  return (
    <Tooltip title="Flux" arrow>
      <LogoWrapper to="/">
        <Box component="img" src={logo} sx={{ height }} alt="Flux Logo" />
      </LogoWrapper>
    </Tooltip>
  );
}

export default Logo;
