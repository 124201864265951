import { get, post, put } from 'apis/apiBase';
import {
  GenerateTenantCodeResponse,
  GetTenantDataResult,
  RegisterTenantRequest,
  RegisterTenantResponse
} from 'types/Tenant';

type TenantHomeData = {
  userCount: number;
  jobCount: number;
  timesheetsAwaitingPaymentCount: number;
};

type TimesheetReportResponseDto = {
  costsByUser: CostReportByUserItem[];
  dateFrom: string;
  dateTo: string;
  costsByJob: CostReportByJobItem[];
};

type CostReportByUserItem = {
  userName: string;
  total: number;
};

type CostReportByJobItem = {
  jobName: string;
  total: number;
};

type GetXeroIntegrationStatusResult = {
  isXeroIntegrationEnabled: boolean;
};

type XeroAccount = {
  code: string | null;
  name: string;
};

type XeroContact = {
  contactID: string;
  name: string;
};

type XeroProject = {
  projectId: string;
  name: string;
};

type TrackingCategory = {
  trackingCategoryId: string;
  trackingOptionId: string;
  categoryName: string;
  optionName: string;
  name: string;
};

type GetStripeDataResult = {
  stripeSubscriptionId: string;
  stripeSubscriptionStatus: string;
  stripeCustomerId: string;
};

const registerTenant = (registerTenantRequest: RegisterTenantRequest) => {
  return post<RegisterTenantResponse>('Tenant/Register', registerTenantRequest);
};

const generateTenantCode = () => {
  return post<GenerateTenantCodeResponse>('Tenant/GenerateCode');
};

const getHomeData = () => {
  return get<TenantHomeData>('Tenant/HomeData');
};

const getHomeReportData = () => {
  return get<TimesheetReportResponseDto>('Timesheet/Report');
};

const getXeroIntegrationStatus = () => {
  return get<GetXeroIntegrationStatusResult>('Tenant/XeroIntegrationStatus');
};

const getStripData = () => {
  return get<GetStripeDataResult>('Tenant/StripeData');
};

const getTenantData = () => {
  return get<GetTenantDataResult>('Tenant');
};

const updateDefaultNominalCode = (defaultNominalCode: string) => {
  return put('Tenant/DefaultNominalCode', { defaultNominalCode });
};

const getXeroAccounts = () => {
  return get<XeroAccount[]>('Xero/Accounts');
};

const getXeroSuppliers = () => {
  return get<XeroContact[]>('Xero/Suppliers');
};

const getXeroProjects = () => {
  return get<XeroProject[]>('Xero/Projects');
};

const getXeroTrackingCategories = () => {
  return get<TrackingCategory[]>('Xero/TrackingCategories');
};

const disconnectFromXero = () => {
  return post('Xero/Disconnect');
};

const tenantApi = {
  registerTenant,
  getHomeData,
  getHomeReportData,
  generateTenantCode,
  getXeroIntegrationStatus,
  getTenantData,
  updateDefaultNominalCode,
  getXeroAccounts,
  getXeroSuppliers,
  getXeroProjects,
  getXeroTrackingCategories,
  disconnectFromXero,
  getStripData
};

export default tenantApi;
