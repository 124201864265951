import { useRoutes } from 'react-router-dom';
import { CssBaseline } from '@mui/material';
import { QueryClient, QueryClientProvider } from 'react-query';
import AdapterDateFns from '@mui/lab/AdapterDateFns';
import LocalizationProvider from '@mui/lab/LocalizationProvider';
import routes from './router';
import useScrollTop from 'hooks/useScrollTop';
import ThemeProvider from 'theme/ThemeProvider';
import QueryCacheManager from 'root/QueryCacheManager';
import { AppSnackbarProvider } from './AppSnackbarProvider';
import { installDataGridLicense } from './DataGridLicense';
import { useAuth } from 'contexts/AuthContext';

installDataGridLicense();

export const App = () => {
  const content = useRoutes(routes);
  const auth = useAuth();
  useScrollTop();

  const queryClient = new QueryClient();

  return (
    <QueryClientProvider client={queryClient}>
      <ThemeProvider>
        <LocalizationProvider dateAdapter={AdapterDateFns}>
          <AppSnackbarProvider>
            <QueryCacheManager>
              <CssBaseline />
              {auth.isInitialized ? content : <>...</>}
            </QueryCacheManager>
          </AppSnackbarProvider>
        </LocalizationProvider>
      </ThemeProvider>
    </QueryClientProvider>
  );
};
