import axios from 'axios';
import { apiConfig } from '../config';

const axiosInt = axios.create({ baseURL: apiConfig.baseUrl.toString() });

export async function get<TResponse>(url: string) {
  const response = await axiosInt.get<TResponse>(url);
  return response.data;
}

export async function post<TResponse, TRequest = any>(
  url: string,
  requestData: TRequest = null
) {
  const response = await axiosInt.post<TResponse>(url, requestData);
  return response.data;
}

export async function put<TResponse, TRequest = any>(
  url: string,
  requestData: TRequest = null
) {
  const response = await axiosInt.put<TResponse>(url, requestData);
  return response.data;
}

export async function del<TResponse>(url: string) {
  const response = await axiosInt.delete<TResponse>(url);
  return response.data;
}

axiosInt.interceptors.response.use(
  (response) => response,
  (error) => {
    console.error(error);
    return Promise.reject(
      (error.response && error.response.data) ||
        (error.message && { message: error.message }) ||
        '❌ There is an error!'
    );
  }
);

export default axiosInt;
