import {
  ReactNode,
  createContext,
  useEffect,
  useReducer,
  useContext
} from 'react';
import { ICurrentUser } from 'types/User';
import axios from 'utils/axios';
import { checkTokenExpiry } from 'utils/jwt';
import api from 'apis/apiBase';
import userApi from 'apis/userApi';
import tenantApi from 'apis/tenantApi';
import { RegisterTenantRequest } from 'types/Tenant';

interface IAuthState {
  isInitialized: boolean;
  isAuthenticated: boolean;
  user: ICurrentUser | null;
}

interface IAuthContext extends IAuthState {
  login: (email: string, password: string) => Promise<void>;
  logout: () => void;
  registerTenant: (
    registerTenantRequest: RegisterTenantRequest
  ) => Promise<void>;
  verifyEmail: (code: string) => Promise<void>;
}

interface AuthProviderProps {
  children: ReactNode;
}

type InitializeAction = {
  type: 'INITIALIZE';
  payload: {
    isAuthenticated: boolean;
    user: ICurrentUser | null;
  };
};

type LoginAction = {
  type: 'LOGIN';
  payload: {
    user: ICurrentUser;
  };
};

type LogoutAction = {
  type: 'LOGOUT';
};

type RegisterAction = {
  type: 'REGISTER';
  payload: {
    user: ICurrentUser;
  };
};

type VerifyEmailAction = {
  type: 'VERIFY_EMAIL';
  payload: {
    user: ICurrentUser;
  };
};

type Action =
  | InitializeAction
  | LoginAction
  | LogoutAction
  | RegisterAction
  | VerifyEmailAction;

const initialAuthState: IAuthState = {
  isAuthenticated: false,
  isInitialized: false,
  user: null
};

const setSession = (accessToken: string | null): void => {
  if (accessToken) {
    localStorage.setItem('accessToken', accessToken);
    api.defaults.headers.common.Authorization = `Bearer ${accessToken}`;
    axios.defaults.headers.common.Authorization = `Bearer ${accessToken}`;
  } else {
    localStorage.removeItem('accessToken');
    delete axios.defaults.headers.common.Authorization;
    delete api.defaults.headers.common.Authorization;
  }
};

const handlers: Record<
  Action['type'],
  (state: IAuthState, action: Action) => IAuthState
> = {
  INITIALIZE: (state: IAuthState, action: InitializeAction) => {
    const { isAuthenticated, user } = action.payload;

    return {
      ...state,
      isAuthenticated,
      isInitialized: true,
      user
    };
  },
  LOGIN: (state: IAuthState, action: LoginAction) => {
    const { user } = action.payload;

    return {
      ...state,
      isAuthenticated: true,
      user
    };
  },
  LOGOUT: (state: IAuthState) => ({
    ...state,
    isAuthenticated: false,
    user: null
  }),
  REGISTER: (state: IAuthState, action: RegisterAction) => {
    const { user } = action.payload;

    return {
      ...state,
      isAuthenticated: true,
      user
    };
  },
  VERIFY_EMAIL: (state: IAuthState, action: VerifyEmailAction) => {
    const { user } = action.payload;
    return {
      ...state,
      user
    };
  }
};

const reducer = (state: IAuthState, action: Action): IAuthState =>
  handlers[action.type] ? handlers[action.type](state, action) : state;

const AuthContext = createContext<IAuthContext | undefined>(undefined);

export const AuthProvider = ({ children }: AuthProviderProps) => {
  const [state, dispatch] = useReducer(reducer, initialAuthState);

  useEffect(() => {
    const initialize = async (): Promise<void> => {
      try {
        const accessToken = window.localStorage.getItem('accessToken');

        if (accessToken && checkTokenExpiry(accessToken)) {
          setSession(accessToken);

          const user = await userApi.getCurrentUser();

          dispatch({
            type: 'INITIALIZE',
            payload: {
              isAuthenticated: true,
              user
            }
          });
        } else {
          dispatch({
            type: 'INITIALIZE',
            payload: {
              isAuthenticated: false,
              user: null
            }
          });
        }
      } catch (err) {
        console.error(err);
        dispatch({
          type: 'INITIALIZE',
          payload: {
            isAuthenticated: false,
            user: null
          }
        });
      }
    };

    initialize();
  }, []);

  const login = async (email: string, password: string): Promise<void> => {
    const { accessToken, user } = await userApi.login(email, password);

    setSession(accessToken);
    dispatch({
      type: 'LOGIN',
      payload: {
        user
      }
    });
  };

  const logout = async () => {
    setSession(null);
    dispatch({ type: 'LOGOUT' });
  };

  const registerTenant = async (
    registerTenantRequest: RegisterTenantRequest
  ) => {
    const {
      loginResponse: { accessToken, user }
    } = await tenantApi.registerTenant(registerTenantRequest);

    setSession(accessToken);
    dispatch({
      type: 'REGISTER',
      payload: {
        user
      }
    });
  };

  const verifyEmail = async (code: string): Promise<void> => {
    const { user } = await userApi.verifyEmailAddress(code);

    dispatch({
      type: 'VERIFY_EMAIL',
      payload: {
        user
      }
    });
  };

  return (
    <AuthContext.Provider
      value={{
        ...state,
        login,
        logout,
        registerTenant,
        verifyEmail
      }}
    >
      {children}
    </AuthContext.Provider>
  );
};

export const useAuth = () => {
  const context = useContext(AuthContext);
  if (context === undefined) {
    throw new Error('useAuth must be used within a AuthProvider');
  }
  return context;
};
