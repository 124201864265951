import { Link as RouterLink } from 'react-router-dom';
import { Box, Link, Typography } from '@mui/material';
import { Helmet } from 'react-helmet-async';
import { LoginUi } from './LoginUi';
import { AnonymousLayout } from 'layouts/AnonymousLayout';

export const LoginMain = () => {
  return (
    <>
      <Helmet>
        <title>Login</title>
      </Helmet>
      <AnonymousLayout title="Sign in">
        <LoginUi />

        <Box my={4}>
          <Typography
            component="span"
            variant="subtitle2"
            color="text.primary"
            fontWeight="bold"
          >
            Don’t have an account yet?
          </Typography>{' '}
          <Link component={RouterLink} underline="hover" to="/account/register">
            <b>Sign up now, it's free</b>
          </Link>
        </Box>
      </AnonymousLayout>
    </>
  );
};
