import { ReactNode, createRef } from 'react';
import { IconButton } from '@mui/material';
import CancelPresentationIcon from '@mui/icons-material/CancelPresentation';
import { SnackbarKey, SnackbarProvider } from 'notistack';

export const AppSnackbarProvider = ({ children }: { children: ReactNode }) => {
  const snackbarRef = createRef<SnackbarProvider>();
  const onClickDismiss = (key: SnackbarKey) => () => {
    snackbarRef.current.closeSnackbar(key);
  };
  return (
    <SnackbarProvider
      ref={snackbarRef}
      action={(key) => (
        <IconButton onClick={onClickDismiss(key)}>
          <CancelPresentationIcon sx={{ color: '#fff', fontSize: '0.9rem' }} />
        </IconButton>
      )}
      maxSnack={6}
      anchorOrigin={{
        vertical: 'top',
        horizontal: 'center'
      }}
      autoHideDuration={3000}
    >
      {children}
    </SnackbarProvider>
  );
};
