import * as Yup from 'yup';
import { Formik } from 'formik';
import { Link as RouterLink, useSearchParams } from 'react-router-dom';
import {
  Box,
  Button,
  TextField,
  Link,
  CircularProgress,
  Alert
} from '@mui/material';
import { useAuth } from 'contexts/AuthContext';
import useRefMounted from 'hooks/useRefMounted';

import { getErrorMessage } from 'utils';

export const LoginUi = () => {
  const [searchParams] = useSearchParams();
  const { login } = useAuth();
  const isMountedRef = useRefMounted();
  const emailAddress = searchParams.get('emailAddress') || '';

  return (
    <Formik
      initialValues={{
        email: emailAddress,
        password: '',
        submit: null
      }}
      validationSchema={Yup.object().shape({
        email: Yup.string()
          .email('The email provided should be a valid email address')
          .max(255)
          .required('The email field is required'),
        password: Yup.string()
          .max(255)
          .required('The password field is required')
      })}
      onSubmit={async (
        values,
        { setErrors, setStatus, setSubmitting }
      ): Promise<void> => {
        try {
          await login(values.email, values.password);

          if (isMountedRef.current) {
            setStatus({ success: true });
            setSubmitting(false);
          }
        } catch (err) {
          console.error(err);
          if (isMountedRef.current) {
            setStatus({ success: false });
            setErrors({ submit: getErrorMessage(err.message) });
            setSubmitting(false);
          }
        }
      }}
    >
      {({
        errors,
        handleChange,
        handleSubmit,
        isSubmitting,
        touched,
        values
      }): JSX.Element => (
        <form noValidate onSubmit={handleSubmit}>
          <TextField
            error={Boolean(touched.email && errors.email)}
            fullWidth
            margin="normal"
            autoFocus={emailAddress ? false : true}
            helperText={touched.email && errors.email}
            label={'Email address'}
            name="email"
            onChange={handleChange}
            type="email"
            value={values.email}
            variant="outlined"
          />
          <TextField
            error={Boolean(touched.password && errors.password)}
            autoFocus={emailAddress ? true : false}
            fullWidth
            margin="normal"
            helperText={touched.password && errors.password}
            label={'Password'}
            name="password"
            onChange={handleChange}
            type="password"
            value={values.password}
            variant="outlined"
          />
          <Box
            alignItems="center"
            display="flex"
            justifyContent="space-between"
          >
            <Link
              component={RouterLink}
              underline="hover"
              to="/account/recover-password"
            >
              <b>{'Lost password?'}</b>
            </Link>
          </Box>

          <Button
            sx={{ mt: 3 }}
            color="primary"
            startIcon={isSubmitting ? <CircularProgress size="1rem" /> : null}
            disabled={isSubmitting}
            type="submit"
            fullWidth
            size="large"
            variant="contained"
          >
            {'Sign in '}
            <>&raquo;</>
          </Button>
          {errors.submit && (
            <Box mt={4}>
              <Alert severity="error">{errors.submit}</Alert>
            </Box>
          )}
        </form>
      )}
    </Formik>
  );
};
