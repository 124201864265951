import { LicenseInfo } from '@mui/x-license-pro';

export const installDataGridLicense = () => {
  const envKey = 'REACT_APP_DATAGRID_LICENSE_KEY';
  const key = process.env[envKey];
  if (!key) {
    console.error(`Could not retrieve key from config called: ${envKey}`);
    return;
  }

  LicenseInfo.setLicenseKey(key);
};
