import { Suspense, lazy } from 'react';
import { Navigate } from 'react-router-dom';
import { RouteObject } from 'react-router';

import Guest from 'components/Guest';
import Authenticated from 'components/Authenticated';

import BaseLayout from 'layouts/BaseLayout';
import AccentSidebarLayout from 'layouts/AccentSidebarLayout';
import SuspenseLoader from 'components/SuspenseLoader';

const Loader = (Component) => (props) =>
  (
    <Suspense fallback={<SuspenseLoader />}>
      <Component {...props} />
    </Suspense>
  );

// Dashboards
const Home = Loader(lazy(() => import('content/dashboards/Home')));

// Pages
const Job = Loader(
  lazy(() =>
    import('content/pages/Job').then((module) => ({ default: module.JobMain }))
  )
);

const User = Loader(
  lazy(() =>
    import('content/pages/User').then((module) => ({
      default: module.UserMain
    }))
  )
);

const Team = Loader(
  lazy(() =>
    import('content/pages/Team').then((module) => ({
      default: module.Team
    }))
  )
);

const Admin = Loader(
  lazy(() =>
    import('content/pages/Admin').then((module) => ({
      default: module.Admin
    }))
  )
);

const TimesheetListView = Loader(
  lazy(() =>
    import('content/pages/Timesheet/All').then((module) => ({
      default: module.TimesheetsAll
    }))
  )
);

const TimesheetsAwaitingPayment = Loader(
  lazy(() =>
    import('content/pages/Timesheet/AwaitingPayment').then((module) => ({
      default: module.TimesheetsAwaitingPayment
    }))
  )
);

const PayReportList = Loader(
  lazy(() =>
    import('content/pages/PayReport').then((module) => ({
      default: module.PayReportsMain
    }))
  )
);

const PayReport = Loader(
  lazy(() =>
    import('content/pages/PayReport').then((module) => ({
      default: module.PayReportSingle
    }))
  )
);

const Timesheet = Loader(
  lazy(() =>
    import('content/pages/Timesheet/Single').then((module) => ({
      default: module.Single
    }))
  )
);

const Support = Loader(
  lazy(() =>
    import('content/pages/Support').then((module) => ({
      default: module.Support
    }))
  )
);

const Privacy = Loader(
  lazy(() =>
    import('content/pages/Privacy').then((module) => ({
      default: module.Privacy
    }))
  )
);

// Auth
const LoginMain = Loader(
  lazy(() =>
    import('content/pages/Auth/Login/').then((module) => ({
      default: module.LoginMain
    }))
  )
);

const RegisterMain = Loader(
  lazy(() =>
    import('content/pages/Auth/Register').then((module) => ({
      default: module.RegisterMain
    }))
  )
);

const RegisterUserMain = Loader(
  lazy(() =>
    import('content/pages/Auth/RegisterUser').then((module) => ({
      default: module.RegisterUserMain
    }))
  )
);

const RecoverPassword = Loader(
  lazy(() => import('content/pages/Auth/RecoverPassword/RecoverPassword'))
);

// Status
const Status404 = Loader(lazy(() => import('content/pages/Status/Status404')));

const paths = {
  home: 'home',
  support: 'support',
  privacy: 'privacy',
  register: 'account/register',
  job: 'job',
  user: 'user',
  team: 'team',
  timesheet: 'timesheet',
  payReport: 'payReport',
  admin: 'admin'
};

const routes: RouteObject[] = [
  {
    path: paths.support,
    element: (
      <Guest>
        <Support />
      </Guest>
    )
  },
  {
    path: paths.privacy,
    element: (
      <Guest>
        <Privacy />
      </Guest>
    )
  },
  {
    path: 'account',
    children: [
      {
        path: 'login',
        element: (
          <Guest>
            <LoginMain />
          </Guest>
        )
      },
      {
        path: 'register',
        element: (
          <Guest>
            <RegisterMain />
          </Guest>
        )
      },
      {
        path: 'recover-password',
        element: (
          <Guest>
            <RecoverPassword />
          </Guest>
        )
      },
      {
        path: 'registerUser',
        element: (
          <Guest>
            <RegisterUserMain />
          </Guest>
        )
      }
    ]
  },
  {
    path: '*',
    element: <BaseLayout />,
    children: [
      {
        index: true,
        element: <Navigate to={paths.register} replace />
      },
      {
        path: 'status',
        children: [
          {
            index: true,
            element: <Navigate to="404" replace />
          },
          {
            path: '404',
            element: <Status404 />
          }
        ]
      },
      {
        path: '*',
        element: <Status404 />
      }
    ]
  },

  // Main

  {
    path: paths.home,
    element: (
      <Authenticated>
        <AccentSidebarLayout />
      </Authenticated>
    ),
    children: [
      {
        index: true,
        element: <Home />
      }
    ]
  },

  {
    path: paths.job,
    element: (
      <Authenticated>
        <AccentSidebarLayout />
      </Authenticated>
    ),
    children: [
      {
        index: true,
        element: <Job />
      }
    ]
  },

  {
    path: paths.user,
    element: (
      <Authenticated>
        <AccentSidebarLayout />
      </Authenticated>
    ),
    children: [
      {
        index: true,
        element: <User />
      }
    ]
  },

  {
    path: paths.team,
    element: (
      <Authenticated>
        <AccentSidebarLayout />
      </Authenticated>
    ),
    children: [
      {
        index: true,
        element: <Team />
      }
    ]
  },

  {
    path: paths.timesheet,
    element: (
      <Authenticated>
        <AccentSidebarLayout />
      </Authenticated>
    ),
    children: [
      { path: 'All', element: <TimesheetListView /> },
      { path: 'AwaitingPayment', element: <TimesheetsAwaitingPayment /> },
      { path: ':timesheetId', element: <Timesheet /> }
    ]
  },

  {
    path: paths.payReport,
    element: (
      <Authenticated>
        <AccentSidebarLayout />
      </Authenticated>
    ),
    children: [
      { path: '', element: <PayReportList /> },
      { path: ':payReportId', element: <PayReport /> }
    ]
  },

  {
    path: paths.admin,
    element: (
      <Authenticated>
        <AccentSidebarLayout />
      </Authenticated>
    ),
    children: [
      {
        index: true,
        element: <Admin />
      }
    ]
  }
];

export default routes;
