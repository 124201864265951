import { useSnackbar } from 'notistack';

const useSnackbarCustom = () => {
  const { enqueueSnackbar } = useSnackbar();

  const enqueueSnackbarSuccess = (message: string) =>
    enqueueSnackbar(message, { variant: 'success', preventDuplicate: true });

  const enqueueSnackbarError = (message: string) =>
    enqueueSnackbar(message, { variant: 'error', preventDuplicate: true });

  return {
    enqueueSnackbarSuccess,
    enqueueSnackbarError
  };
};

export default useSnackbarCustom;
